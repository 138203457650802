import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useFormContext, get } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import TrashIcon from "components/Vectors/TrashIcon";

function ProductUploadImage({ name, maxFiles = 10, required, ...props }) {
  const {
    register,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchImage = watch(name);
  const [imageFile, setImageFile] = useState(watchImage || []);

  const onDrop = useCallback((acceptedFiles) => {
    setImageFile((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const fileSizeValidator = (file) => {
    if (file?.size > 2_000_000) {
      return { code: "file-too-large", message: `File is larger than 2MB` };
    }
    return null;
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      validator: fileSizeValidator,
      onDrop,
      maxFiles,
    });

  useEffect(() => {
    setValue(name, imageFile);
  }, [imageFile, name, setValue]);

  useEffect(() => {
    register(name, { required: required ? "This field is required" : "" });
  }, [name, register, required]);

  const fileRejectionItems = fileRejections.map(({ file }) => (
    <span className="input-err-msg" key={file.path}>
      {file.path} is greater than 1MB
    </span>
  ));

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFileArray = Array.from(selectedFiles);

    if (selectedFiles[0]?.size > 1_000_000) {
      setError(name, {
        message: `${selectedFiles[0]?.name} is greater than 1MB`,
      });
    } else {
      clearErrors(name);
      setImageFile((prev) => [...prev, ...selectedFileArray]);
    }
  };

  const handleDelete = (file) => {
    setImageFile((prev) => prev.filter((item) => item !== file));
  };

  const error = get(errors, name);
  return (
    <div>
      <div className="flex flex-col mt-6 space-y-2">
        <div className="flex flex-wrap mt-4 gap-4">
          {imageFile.map((item, i) => (
            <div className="relative h-40 w-40" key={i}>
              <img
                src={
                  typeof item === "string" ? item : URL.createObjectURL(item)
                }
                alt="img"
                className="h-full w-[100%] rounded-[8px] object-cover"
              />
              <IconButton
                component="label"
                sx={{
                  background: "#181820B2 !important",
                  position: "absolute",
                  height: "34px",
                  width: "34px",
                  cursor: "pointer",
                }}
                className=" absolute top-2 right-2 cursor-pointer transition"
                onClick={() => {
                  handleDelete(item);
                  // removeFile(i);
                }}
              >
                <TrashIcon />
              </IconButton>
            </div>
          ))}
          <label
            htmlFor="file-upload"
            {...getRootProps()}
            className="h-40 file-upload-label border-2 border-dotted border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer"
          >
            <div className="upload-icon flex items-center justify-center bg-gray-100 rounded-full p-3 w-10 h-10 mb-2">
              <span className="text-3xl text-gray-500">+</span>
            </div>
            <div class="text-center">
              <span class="text-purple-500 font-semibold">Click to upload</span> or drag and drop
            </div>
            <p className="text-gray-500 text-xs mt-1">PNG, JPG, JPEG.</p>
          </label>
          <input
            {...getInputProps()}
            type="file"
            accept="image/*"
            multiple
            onChange={onSelectFile}
            className="hidden"
          />
        </div>
      </div>
      <div>{fileRejectionItems}</div>
      {error && <div className="input-err-msg">{error.message}</div>}
    </div>
  );
}

export default ProductUploadImage;
