import * as React from "react";
import {useFormContext, get, Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  MenuItem,
  TextField,
} from "@mui/material";
import "./style.scss";
import CheckIcon from "@mui/icons-material/Check";
import {useEffect} from "react";

const filter = createFilterOptions();

export default function AutoCompleteField({
  name,
  errMsg,
  required = true,
  selectOption = [],
  label,
  handleCustomChange,
  className = "",
  spaceY = true,
  variant = "free",
  bg = "white",
  defaultValue,
  ...props
}) {
  const {
    register,
    control,
    formState: {errors},
  } = useFormContext();

  const error = get(errors, name);

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : required,
    });
  }, [name, register, required]);

  return (
    <div
      className={`form-group-wrapper  ${
        !spaceY ? "" : variant === "free" ? "my-4" : "my-5"
      }`}
    >
      <FormControl
        sx={{width: "100%"}}
        className={`form-group select-group ${className}  bg-${bg}`}
      >
        {label && (
          <label
            className={`font-medium block w-full text-gray-500 ${
              variant === "condensed" ? "absolute -top-3" : "static -ml-1 pb-2"
            }  px-2 text-sm`}
          >
            {label}{" "}
            {props.required ? <span className="text-error">*</span> : ""}
          </label>
        )}

        <Controller
          control={control}
          defaultValue={defaultValue}
          name={name}
          render={({field: {onChange, value = [] || ""}}) => {
            return (
              <Autocomplete
                {...props}
                disablePortal
                isOptionEqualToValue={(option, value) =>
                  typeof option === "string"
                    ? option === value
                    : option.label === (value?.label ?? value)
                }
                style={{height: props.multiple ? "auto" : "40px"}}
                className={`select-mui ${props.multiple && "autocomplete"}  ${
                  error ? "invalid" : ""
                }`}
                getOptionLabel={(option) => {
                  return option.value || option.label || option || "";
                }}
                options={selectOption ? selectOption : []}
                filterOptions={(options = [], params) => {
                  // Suggest the creation of a new value if the autocomplete is a free solo type
                  if (props.freeSolo) {
                    const filtered = filter(options, params);
                    const {inputValue} = params;
                    const isExisting = options.some(
                      (option) => inputValue === (option.value || option)
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(inputValue);
                    }
                    return filtered;
                  } else return filter(options, params) ?? [];
                }}
                handleHomeEndKeys
                onChange={(e, value) => {
                  onChange(value?.value ?? value ?? "");
                  if (handleCustomChange) {
                    handleCustomChange(value);
                  }
                }}
                value={
                  props.loading
                    ? ""
                    : props.multiple
                    ? selectOption.filter((el) =>
                        value.includes(el.value ?? el)
                      ) ?? []
                    : selectOption?.find((el) => (el.value ?? el) === value)?.label ?? value ?? ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={props.placeholder}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {props.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(renderProps, option, {selected}) => (
                  <MenuItem
                    {...renderProps}
                    className="menu-item w-full"
                    sx={{
                      justifyContent: "space-between",
                      background: selected ? "#FAF5FF" : "transparent",
                    }}
                  >
                    {typeof option === "string" ? option : option?.label}
                    {props.multiple && (
                      <Checkbox
                        icon={<></>}
                        checkedIcon={
                          <CheckIcon
                            color="primary"
                            fontSize="small"
                            sx={{p: 0}}
                          />
                        }
                        sx={{border: "none", p: 0}}
                        checked={selected}
                      />
                    )}
                  </MenuItem>
                )}
              />
            );
          }}
        />
        {error && <div className="input-err-msg">{error.message}</div>}
      </FormControl>
    </div>
  );
}
