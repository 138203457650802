import ResetPassword from "pages/auth/ResetPassword";
import SetNewPassword from "pages/auth/SetNewPassword";
import SetPasswordSuccessful from "pages/auth/SetPasswordSuccessful";
import { BrowserRouter, Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import AdminLogin from "pages/auth/login";
import DasboardLayout from "templates/DashboardLayout/DashboardLayout";
import AdminDashboard from "pages/Dashboard/Home";
import AdminInfluencers from "pages/Dashboard/Influencers";
import EachInfluencer from "pages/Dashboard/Influencers/EachInfluencer";
import AdminCampaigns from "pages/Dashboard/Campaigns";
import AdminServices from "pages/Dashboard/Services";
import AdminEachService from "pages/Dashboard/Services/EachService";
import AdminSettings from "pages/Dashboard/Settings";
import ScrollToTop from "components/ScrollToTop";
import CategorizedTickets from "pages/Dashboard/Tickets/CategorizedTickets";
import { useGetUser } from "hooks/getUserHook";
import Notifiactions from "pages/Dashboard/Notifications";
import EachTicket from "components/EachTicket";
// import BackBtn from "components/BackBtn";
// import SubAdminDashboard from "pages/Dashboard/Home/SubAdminDashboard";
import EachEngagement from "pages/Dashboard/Engagements/EachEngagement";
import AdminEngagements from "pages/Dashboard/Engagements/AdminEngagements";
// import {Button} from "@mui/material";
import AdminAdministrators from "pages/Dashboard/Administrators";
import EachAdministrator from "pages/Dashboard/Administrators/EachAdministrator";
import ProfilePage from "pages/Dashboard/Profile";
import EditProfile from "pages/Dashboard/Profile/EditProfile";
// import DurationFilter from "components/Vectors/DurationFilter";
import AdminSettingsCategory from "pages/Dashboard/Settings/AdminSettingsCategory";
import EachAdminCampaign from "pages/Dashboard/Campaigns/EachAdminCampaign";
import AdminCreators from "pages/Dashboard/Creators/AdminCreators";
import Clients from "pages/Dashboard/Clients";
import EachClient from "pages/Dashboard/Clients/EachClient";
import AdminJobs from "pages/Dashboard/Jobs";
import EachAdminJob from "pages/Dashboard/Jobs/EachAdminJob";
import EachCreator from "pages/Dashboard/Creators/EachCreator";
import AdminPushNotification from "pages/Dashboard/PushNotification";
import Transactions from "pages/Dashboard/Transactions";
import EditClient from "pages/Dashboard/Clients/EditClient";
import ClientProducts from "pages/Dashboard/Clients/ClientProducts";
import AddProduct from "pages/Dashboard/Clients/AddProduct";
import EditProduct from "pages/Dashboard/Clients/EditProduct";
import AddClient from "pages/Dashboard/Clients/AddClient";

function AllRoutes() {
 let { isLoggedIn, user } = useGetUser();

 const PrivateRoute = ({ children }) => {
  let location = useLocation();

  // console.log(isLoggedIn)
  return (
   <>
    {isLoggedIn ? (
     children || <Outlet />
    ) : (
     <Navigate to={`/login?redirectTo=${location.pathname}`} state={{ from: location }} />
    )}
   </>
  );
 };

 const AdminModule = ({ children, module }) => {
  return (
   <>
    {user?.roles[0]?.name.toLowerCase() === "super admin" ||
    user?.permissions?.some((el) => el.name.toLowerCase() === module?.toLowerCase()) ? (
     children || <Outlet />
    ) : (
     <Navigate to={`/`} />
    )}
   </>
  );
 };

 return (
  <BrowserRouter>
   <ScrollToTop />
   <Routes>
    {/* admin routes */}
    <Route
     path="/"
     element={
      <div>
       <Outlet />
      </div>
     }
    >
     {/* auth routes */}
     <Route path="login" element={isLoggedIn ? <Navigate to="/" /> : <AdminLogin />} />
     <Route path="reset-password" element={isLoggedIn ? <Navigate to="/" /> : <ResetPassword />} />
     <Route path="set-password" element={isLoggedIn ? <Navigate to="/" /> : <SetNewPassword />} />
     <Route path="set-password-success" element={isLoggedIn ? <Navigate to="/" /> : <SetPasswordSuccessful />} />

     {/* dashboard routes */}
     <Route
      path=""
      element={
       <PrivateRoute>
        <DasboardLayout type="admin" />
       </PrivateRoute>
      }
     >
      <Route path="" element={<AdminDashboard />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="profile/edit-profile" element={<EditProfile />} />
      <Route path="brands" element={<AdminModule module={"clients"} />}>
       <Route path="" element={<Clients />} />
       <Route path=":id" element={<EachClient />} />
       <Route path="create" element={<AddClient />} />
       <Route path="edit/:id" element={<EditClient />} />
       <Route path="products/:id" element={<ClientProducts />} />
       <Route path="add-product/:id" element={<AddProduct />} />
       <Route path=":clientId/edit-product/:id" element={<EditProduct />} />
      </Route>
      <Route path="influencers" element={<AdminModule module={"influencers"} />}>
       <Route path="" element={<AdminInfluencers />} />
       <Route path=":id" element={<EachInfluencer />} />
      </Route>
      <Route path="creators" element={<AdminModule module={"creators"} />}>
       <Route path="" element={<AdminCreators />} />
       <Route path=":id" element={<EachCreator />} />
      </Route>
      <Route path="campaigns" element={<AdminModule module={"campaigns"} />}>
       <Route path="" element={<AdminCampaigns />} />
       <Route path=":id" element={<EachAdminCampaign />} />
      </Route>
      <Route path="jobs" element={<AdminModule module={"jobs"} />}>
       <Route path="" element={<AdminJobs />} />
       <Route path=":id" element={<EachAdminJob />} />
      </Route>
      <Route path="engagements" element={<AdminModule module={"engagements"} />}>
       <Route path="" element={<AdminEngagements />} />
       <Route path=":id" element={<EachEngagement />} />
      </Route>
      <Route path="transactions" element={<AdminModule module={"transactions"} />}>
       <Route path="" element={<Transactions />} />
      </Route>
      <Route path="administrators" element={<AdminModule module={"administrators"} />}>
       <Route path="" element={<AdminAdministrators />} />
       <Route path="admin/:id" element={<EachAdministrator />} />
      </Route>
      <Route path="tickets" element={<AdminModule module={"tickets"} />}>
       <Route path="" element={<CategorizedTickets />} />
       <Route
        path=":id"
        element={
         <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
          <EachTicket />
         </div>
        }
       />
      </Route>
      <Route path="services" element={<AdminModule module={"services"} />}>
       <Route path="" element={<AdminServices />} />
       <Route path=":id" element={<AdminEachService />} />
      </Route>

      {/* <Route path="reviews" element={<AdminReviews />} /> */}
      {/* <Route path="reviews/:id" element={<EachUserReview />} /> */}

      <Route path="settings" element={<AdminSettings />} />
      <Route path="settings/category/:userType/:id" element={<AdminSettingsCategory />} />
      <Route path="notifications" element={<Notifiactions />} />
      <Route path="push-notification" element={<AdminPushNotification />} />
     </Route>
    </Route>
   </Routes>
  </BrowserRouter>
 );
}

export default AllRoutes;
