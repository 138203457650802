import React, { useCallback, useState, useEffect } from "react";
import { Fade, IconButton, Slide, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDropzone } from "react-dropzone";
import TrashIcon from "components/Vectors/TrashIcon";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import { showToast } from "store/store.hooks";
import { useDeleteClientProductImageMutation, useGetSingleClientProductQuery, useUploadClientProductImageMutation } from "services";

function EditProductImage({ isOpen, onClose, productId, clientId, existingImages = [], maxFiles = 10, refetch }) {
  const [images, setImages] = useState(existingImages);
  const [newImages, setNewImages] = useState([]);
  const [deletingImage, setDeletingImage] = useState(null);
  const [uploadProductImage, { isLoading: isUploading }] = useUploadClientProductImageMutation();
  const [deleteProductImage] = useDeleteClientProductImageMutation();

  const { data: productData, refetch: refetchProduct } = useGetSingleClientProductQuery({ clientId: clientId, id: productId });

  const onDrop = useCallback(async (acceptedFiles) => {
    const fileList = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setNewImages((prev) => [...prev, ...fileList]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: maxFiles - images.length,
    accept: "image/*",
  });

  const handleDelete = async (image) => {
    setDeletingImage(image);

    if (newImages.some((img) => img.preview === image.preview)) {
      setNewImages((prev) => prev.filter((img) => img.preview !== image.preview));
      setDeletingImage(null);
    } else {
      try {
        await deleteProductImage({ productId, mediaId: image.id }).unwrap();
        setImages((prev) => prev.filter((img) => img.id !== image.id));
        showToast("Image deleted Successfully");
      } catch (err) {
        console.error("Failed to delete image:", err);
        handleError("Error deleting image. Please try again.");
      } finally {
        setDeletingImage(null);
      }
    }
  };

  const handleUploadNewImages = async () => {
    if (newImages.length === 0) {
      showToast("Please add some images to upload.", 'error');
      return;
    }

    const formData = new FormData();
    newImages.forEach((imageObj) => formData.append("images[]", imageObj.file));

    try {
      await uploadProductImage({ productId, body: formData }).unwrap();

      await refetchProduct();

      showToast("Image Uploaded Successfully");

      if (productData) {
        const updatedImages = productData.data.media.map((media) => media);
        setImages(updatedImages);
        setNewImages([]);
      }

      onClose();
    } catch (err) {
      console.error("Failed to upload images:", err);
      showToast("Error uploading images. Please try again.", 'error');
    }
  };

  // Effect to handle fetching updated images when the product data changes
  useEffect(() => {
    if (productData) {
      const updatedImages = productData.data.media.map((media) => ({
        id: media.id,
        original_url: media.original_url,
      }));
      setImages(updatedImages);
      setNewImages([]);
    }
  }, [productData]); // Only update when productData changes

  return (
    <Fade in={isOpen}>
      <div
        onClick={(e) => e.target === e.currentTarget && onClose()}
        className="modal-wrap"
      >
        <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
          <div className="edit-product modal-content justify-center">
            <div className="flex justify-between">
              <h4 className="font-semibold text-black text-md sm:text-xl md:text-2xl">
                Edit Product Images
              </h4>
              <button
                className="flex items-center gap-1 text-sm text-black2 font-semibold"
                onClick={() => onClose()}
              >
                <HighlightOffIcon /> cancel
              </button>
            </div>
            <div className="flex mt-6">
              <div className="flex flex-row sm:justify-center flex-wrap mt-4 gap-4">
                {/* Render existing images */}
                {images.map((image) => (
                  <div className="relative h-24 w-24 sm:h-48 sm:w-48" key={image.id}>
                    <img
                      src={image.original_url || image.preview} // Use preview or the original URL
                      alt="img"
                      className="h-full w-full rounded-[8px] object-cover"
                    />
                    {deletingImage?.id === image.id && (
                      <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                        <CircularProgress 
                          sx={{
                            color: "#8937CE",
                          }}
                          size="1.2rem" 
                        />
                      </div>
                    )}
                    <IconButton
                      component="label"
                      sx={{
                        background: "#c2c2c2 !important",
                        position: "absolute",
                        height: "34px",
                        width: "34px",
                        cursor: "pointer",
                      }}
                      className="absolute top-2 right-2 cursor-pointer transition"
                      onClick={() => handleDelete(image)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}

                {/* Render new images added via dropzone */}
                {newImages.map((imageObj, index) => (
                  <div className="relative h-24 w-24 sm:h-48 sm:w-48" key={`new-${index}`}>
                    <img
                      src={imageObj.preview}
                      alt="img"
                      className="h-full w-full rounded-[8px] object-cover"
                    />
                    <IconButton
                      component="label"
                      sx={{
                        background: "#c2c2c2 !important",
                        position: "absolute",
                        height: "34px",
                        width: "34px",
                        cursor: "pointer",
                      }}
                      className="absolute top-2 right-2 cursor-pointer transition"
                      onClick={() => {
                        // Remove new image from state without backend interaction
                        setNewImages((prev) => prev.filter((_, idx) => idx !== index));
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}

                {/* Dropzone */}
                {images.length + newImages.length < maxFiles && (
                  <div className="relative h-24 w-24 sm:h-48 sm:w-48">
                    <label
                      htmlFor="file-upload"
                      {...getRootProps()}
                      className="w-full h-full file-upload-label border-2 border-dotted border-gray-300 rounded-lg p-3 sm:p-6 flex flex-col items-center justify-center cursor-pointer"
                    >
                      <div className="upload-icon flex items-center justify-center bg-gray-100 rounded-full p-3 w-5 h-5 sm:w-10 sm:h-10 mb-2">
                        <AddCircleOutlineIcon fontSize="large" />
                      </div>
                      <div className="text-center text-xs md:text-md">
                        <span className="text-purple-500 font-semibold">Click to upload</span> {" "}
                        <span className="hidden sm:inline">or drag and drop</span>
                      </div>
                      <p className="text-gray-500 text-xs mt-1 hidden sm:block">PNG, JPG, JPEG</p>
                    </label>
                  </div>
                )}

                <input {...getInputProps()} className="hidden" />
              </div>
            </div>

            <div className="mt-3">
              <p className="text-sm">Upload max of <span className="text-purple-600">{maxFiles} Images.</span></p>
            </div>

            {/* Button to upload new images */}
            <LoadingButton
              loading={isUploading}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#ffffff",
                  }}
                  size="1.2rem"
                />
              }
              onClick={handleUploadNewImages}
              disabled={newImages.length === 0}
              sx={{ width: "100%", mt: 2 }}
              variant="contained"
            >
              Upload New Images
            </LoadingButton>
          </div>
        </Slide>
      </div>
    </Fade>
  );
}

export default EditProductImage;
