import { Avatar, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import ValidatedInput from "components/forms/ValidatedInput";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import { showToast } from "store/store.hooks";
import RadioBtns from "components/forms/RadioBtns";
import AutoCompleteField from "components/forms/AutoCompleteField";
import moment from "moment";
// import PhoneNumberInput from "components/forms/PhoneNumberInput/PhoneNumberInput";
import PhoneInputWrapped from "components/forms/PhoneInputWrapped";
import GooglePlacesTextField from "components/forms/GooglePlacesTextField";
import {
 useCreateClientMutation,
 useGetCitiesByStateQuery,
 useGetCountriesQuery,
 useGetStateByCountryQuery,
} from "services";
import BackBtn from "components/BackBtn";
import { useNavigate } from "react-router-dom";

const MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 18);

const AddClient = () => {
 const methods = useForm({
  mode: "all",
 });
 const navigate = useNavigate();
 const countryId = methods.watch("country_id") || "1";
 const stateId = methods.watch("state_id");
 const { data: allCountries } = useGetCountriesQuery();
 const { data: allStates } = useGetStateByCountryQuery(countryId);
 const { data: allCities } = useGetCitiesByStateQuery(stateId);

 const countries = allCountries?.data;
 const states = allStates?.data;
 const cities = allCities?.data;

 const { setValue, register } = methods;
 const [localImgUrl, setLocalImgUrl] = useState("");
 const [createClient, { isLoading }] = useCreateClientMutation();

 const onSubmit = async (data) => {
  const body = new FormData();
  Object.entries(data).forEach(([key, value]) => {
   if (value instanceof Array) {
    for (const val of value) {
     body.append(`${key}[]`, val);
    }
   } else if (key === "profile_image") {
    if (value.length > 0) body.append(key, value[0]);
   } else if (key === "dob") {
    body.append(key, moment(value).format("DD/MM/YYYY"));
   } else if (key === "address") {
    body.append(key, value);
    body.append("residential_address", value);
   } else body.append(key, value);
  });

  //   for(const [key, value] of body.entries()){
  //     console.log(`${key}: ${value}`);
  //   }
  //   return;

  try {
   let res = await createClient(body).unwrap();
   showToast("Brand Profile created successfully.");
   navigate(`/brands/`);
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <div class="min-h-screen p-8 bg-grey100">
   <BackBtn className="mb-0" />
   <div className="flex items-center mb-4 gap-x-2">
    <h1 className="title">Add New Brand</h1>
   </div>
   <div className="px-5 py-8 rounded-lg bg-white flex flex-col">
    <FormProvider {...methods}>
     <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="flex justify-between items-start">
       <div className="w-full">
        <div className="flex gap-4 mt-7 items-center w-full">
         <Avatar sx={{ width: 100, height: 100 }} src={localImgUrl} />
         <div className="flex flex-col gap-3 max-[600px]:w-full">
          <Button
           component="label"
           variant=""
           sx={{
            border: "1px solid #BFBFCF",
            background: "white",
            borderRadius: "8px",
            fontWeight: 600,
           }}
          >
           <input
            type="file"
            {...register("profile_image", {
             required: false,
             onChange: async (e) => {
              let file = e.target.files && e.target?.files[0];
              if (file) {
               const reader = new FileReader();
               reader.readAsDataURL(file);
               reader.addEventListener(
                "load",
                function () {
                 setLocalImgUrl(reader?.result || "");
                },
                false,
               );
              }
             },
            })}
            hidden
            accept="image/png, image/jpeg"
           />
           Upload Photo
          </Button>
         </div>
        </div>

        <div className="space-y-4 max-w-[100%]">
         <div className="mt-5">
          <h3 class="tex-lg my-4 text-grey10 font-semibold">Personal Information</h3>
          <div className="flex flex-col md:flex-row md:gap-x-2 gap-y-3">
           <ValidatedInput name="first_name" label="First Name" placeholder="John" variant="contained" />
           <ValidatedInput name="last_name" label="Last Name" placeholder="Ele" variant="contained" />
          </div>
          <div className="flex flex-col md:flex-row md:gap-x-2 gap-y-3 mt-4">
           <ValidatedInput label="Email Address" name="email" placeholder="Enter Email Address" variant="contained" />
           <ValidatedInput
            name="address"
            label="Residential Address"
            placeholder="Enter Residential Address"
            variant="contained"
           />
          </div>
         </div>
         <div className="flex flex-col md:flex-row md:gap-x-2">
          <RadioBtns
           containerClass="mt-5"
           wrapperClass="grid-cols-3 mt-2 max-[600px]:grid-cols-1"
           name="gender"
           options={["Male", "Female", "Others"]}
           label="Select gender"
          />
         </div>
         <div>
          <h3 class="tex-lg my-4 text-grey10 font-semibold">Business Information</h3>
          <div className="flex flex-col md:flex-row md:gap-x-2 gap-y-4 md:gap-y-0">
           <ValidatedInput
            name="business_name"
            label="Business name"
            placeholder="Enter Business name"
            variant="contained"
            required
           />
           <GooglePlacesTextField
            name="business_address"
            label="Business address"
            variant="contained"
            placeholder="Enter Business address"
            required={true}
           />
           <ValidatedInput
            name="business_reg_no"
            label="Business Registration No. (CAC) (optional)"
            placeholder="Enter registration number (CAC)"
            required={false}
            variant="contained"
           />
          </div>
         </div>

         {/* Location  */}
         <div className="flex flex-col md:flex-row justify-between gap-x-2">
          <AutoCompleteField
           name="country_id"
           selectOption={countries?.map((country) => ({
            label: country?.name,
            value: country?.id,
           }))}
           label="Country of Residence"
           placeholder="Select your country"
           inputValue={"Nigeria"}
           spaceY={false}
           defaultValue={"1"}
          />
          <AutoCompleteField
           name="state_id"
           label="State"
           spaceY={false}
           variant="contained"
           selectOption={states?.map((state) => ({
            label: state?.name,
            value: state?.id,
           }))}
           placeholder="Select your state"
          />
          <AutoCompleteField
           name="city_id"
           label="City"
           spaceY={false}
           variant="contained"
           selectOption={cities?.map((city) => ({
            label: city?.name,
            value: city?.id,
           }))}
           placeholder="Select your city"
          />
         </div>

         <PhoneInputWrapped
          id="1"
          className="mt-12"
          spaceY={false}
          name="phone_number"
          variant="free"
          label="Phone Number"
         />

         {/* <AutoCompleteField
            label='Language(s) spoken'
            name='languages'
            placeholder='Select'
            selectOption={['Yoruba', 'English', 'French', 'Spanish']}
            multiple
        /> */}
         {/*<ValidatedTextArea
									maxLength={150}
									name="about_me"
									label="Bio"
									placeholder="Describe yourself and what you can offer"
									/>*/}
         <LoadingButton
          loadingIndicator={
           <CircularProgress
            sx={{
             color: "#fff",
            }}
            size="1.2rem"
           />
          }
          loading={isLoading}
          type="submit"
          variant="contained"
         >
          Create Client
         </LoadingButton>
        </div>
       </div>
      </div>
     </form>
    </FormProvider>
   </div>
  </div>
 );
};

export default AddClient;
