import {
 Avatar,
 Button,
 Checkbox,
 CircularProgress,
 FormControlLabel,
 FormGroup,
 IconButton,
 Skeleton,
} from "@mui/material";
import BackBtn from "components/BackBtn";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import ErrorMsg from "components/ErrorMsg";
import { getUserDetails, handleError } from "utils";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { showToast } from "store/store.hooks";
import { useEffect, useState } from "react";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import DeleteAdminModal from "./Widgets/DeleteAdminModal";
import SuspendAdminModal from "./Widgets/SuspendAdminModal";
import { useGetUser } from "hooks/getUserHook";
import ToggleSwitch from "components/ToggleSwitch";
import { MoreHoriz } from "@mui/icons-material";
import DropDownWrapper from "components/DropDownWrapper";
import {
 useAssignPermissionMutation,
 useGetAllPermissionQuery,
 useGetEachAdminQuery,
 useGetSinglePermissionQuery,
 useRevokeAllPermissionsMutation,
 useRevokePermissionMutation,
 useUnSuspendAdminMutation,
} from "services";
import InputField from "components/forms/InputField";
import { useGetLoggedInUserQuery } from "services";
import EditIcon from "components/Vectors/EditIcon";
import AddNewAdminModal from "./Widgets/AddNewAdminModal";
import DeleteIcon from "components/Vectors/DeleteIcon";

const EachAdministrator = () => {
 const { id } = useParams();
 const { data, isLoading, isError, error } = useGetEachAdminQuery(id);
 const { data: profileInfo } = useGetLoggedInUserQuery();
 const [unSuspendAdmin, { isLoading: isUnSuspendingAdmin }] = useUnSuspendAdminMutation();
 const [openSuspendModal, setopenSuspendModal] = useState(false);
 const [openDeleteModal, setopenDeleteModal] = useState(false);
 const admin = data?.data;
 const { user } = useGetUser();
 const handleUnsuspendAdmin = async () => {
  try {
   const res = await unSuspendAdmin(id).unwrap();
   showToast(res?.message);
  } catch (error) {
   handleError(error);
  }
 };
 const [showEditModal, setShowEditModal] = useState(false);
 const [rolesList, setRolesList] = useState([]),
   [search, setSearch] = useState(""),
  [action, setAction] = useState({ name: "", value: "" });
 const { data: allPermission, isLoading: allPermissionsLoading } = useGetAllPermissionQuery(),
  { data: roleInfo } = useGetSinglePermissionQuery(id),
  [assignPermission, { isLoading: isAssigning }] = useAssignPermissionMutation(),
  [revokeAllPermissions, { isLoading: isRevokingAllPermission }] = useRevokeAllPermissionsMutation(),
  [revokePermission, { isLoading: isRevoking }] = useRevokePermissionMutation();

 useEffect(() => {
  setRolesList(
   search.length > 0
    ? allPermission?.data
       .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
       .map((permission) => ({
        label: permission?.name,
        value: admin?.permissions?.some(({ id }) => id === permission.id),
        id: permission.id,
       }))
    : allPermission?.data.map((permission) => ({
       label: permission?.name,
       value: admin?.permissions?.some(({ id }) => id === permission.id),
       id: permission.id,
      })),
  );
 }, [allPermission, admin?.permissions, profileInfo, search]);

 const handleSwitch = (label) => {
  const role = rolesList.find(({ label: l }) => l === label);
  setRolesList((roles) => [
   ...roles.map((rol) => {
    if (rol.label === role.label) {
     return { label: role.label, value: !role.value, id: role.id };
    }
    return rol;
   }),
  ]);
 };
 const revoke = async (label) => {
  handleSwitch(label);
  setAction({ name: "revoke", value: label });
 };

 const assign = async (label) => {
  handleSwitch(label);
  setAction({ name: "assign", value: label });
 };
 const handleSwitchAll = async () => {
  const isAllChecked = rolesList.every(({ value }) => Boolean(value) === true);
  setRolesList((role) => [
   ...role.map((rol) => ({
    label: rol.label,
    value: isAllChecked ? false : true,
    id: rol.id,
   })),
  ]);
  isAllChecked ? setAction({ name: "revokeAll", value: "" }) : setAction({ name: "assignAll", value: "" });
 };
 const permissionOpt = {
  revoke: async (label) => {
   const res = await revokePermission({
    admin_id: id,
    permission: label,
   }).unwrap();
   if (res.error === false) {
    showToast(`Permission ${label} revoked successfully`);
   }
  },
//   assign: async (label) => { 
//    const res = await assignPermission({
//     admin_id: id,
//     permissions: [label],
//    }).unwrap();
//    if (res.error === false) {
//     showToast(`Permission ${label} assigned successfully`);
//    } else {
//     handleError(res.error);
//    }
//   },
  assign: async (permissions) => { 
   const res = await assignPermission({
    admin_id: id,
    permissions: permissions,
   }).unwrap();
   if (res.error === false) {
    showToast(`Permissions assigned successfully`);
   } else {
    handleError(res.error);
   }
  },
  revokeAll: async () => {
   const res = await revokeAllPermissions({
    admin_id: id,
    permissions: admin?.permissions?.map(({ name }) => name),
   }).unwrap();
   if (res.error === false) {
    showToast("All permissions revoked successfully");
   }
  },
  assignAll: async () => {
   const res = await assignPermission({
    admin_id: id,
    permissions: [rolesList?.map(({ label }) => label)],
   });
   if (res.error === false) {
    showToast("All permissions assigned successfully");
   } else {
    handleError(res?.error);
   }
  },
 };

//  const handleUpdatePermission = () => {
//   permissionOpt[action.name](action.value);
//  };

 // Directly modifying permissions when is is successful.
 const handleUpdatePermission = () => {
   const selectedPermissions = rolesList.filter(({ value }) => value).map(({ label }) => label);

   if (selectedPermissions.length > 0) {
     permissionOpt.assign(selectedPermissions);
   } else {
     permissionOpt.revokeAll();
   }
 };

 // Exclude Jobs, Creators and Engagements Permissions
 const filteredRolesList = rolesList?.filter(el => (el.label !== "Jobs" && el.label !== "Creators" && el.label !== "Engagements"))

 if (isLoading) return <Loader />;
 if (isError) return <ErrorMsg error={error} />;
 return (
  <div className="bg-white h-full p-6 lg:px-10 px-4">
   <BackBtn />
   <div className="flex flex-col gap-4 md:flex-row md:justify-between mt-10">
    <div className="flex gap-4 items-center">
     <Avatar sx={{ width: 80, height: 80 }} src={getUserDetails(admin).img} />
     <div>
      <h2 className="text-base  mb-1 text-grey10 font-medium">{getUserDetails(admin).name || "N/A"}</h2>
      <p className="text-sm text-grey50">{"Admin"}</p>
     </div>
    </div>

    {/* change null to dropdown wrapper when api is implemented */}
    {user?.roles?.[0]?.name === "Super Admin" && String(user?.id) !== id && null}
    <DropDownWrapper
     position="right"
     contentPadding="px-0"
     closeOnBtnClick={false}
     action={
      <IconButton
       size="large"
       sx={{
        height: "54.2px",
        width: "54.2px",
        bgcolor: "#F2F4F7",
        borderRadius: "16px",
        "&:hover": {
         bgcolor: "#F2F4F7",
        },
       }}
      >
       <MoreHoriz width={24} height={24} />
      </IconButton>
     }
    >
     <div className="space-y-1">
      {user?.roles?.[0]?.name === "Super Admin" && user.id !== admin?.id && (
       <Button
        color="inherit"
        onClick={() => {
         setShowEditModal(true);
        }}
        startIcon={<EditIcon />}
       >
        Edit
       </Button>
      )}

      {admin?.status !== "inactive" && (
       <LoadingButton
        loadingIndicator={<CircularProgress sx={{ color: "#000" }} size={16} />}
        loading={isUnSuspendingAdmin}
        onClick={admin?.status === "suspended" ? handleUnsuspendAdmin : () => setopenSuspendModal(true)}
        color="inherit"
        startIcon={<DoDisturbAltOutlinedIcon />}
       >
        {admin?.status === "suspended" ? "Unsuspend" : "Suspend"}
       </LoadingButton>
      )}
      <Button
       color="error"
       sx={{ textTransform: "none" }}
       onClick={() => setopenDeleteModal(true)}
       startIcon={<DeleteIcon />}
      >
       Delete
      </Button>
     </div>
    </DropDownWrapper>
   </div>

   <div className="flex flex-col gap-4 md:flex-row md:justify-between mt-10">
    <div>
     <p className="font-semibold mt-6 mb-4 text-base text-grey10">Other information</p>

     <div className="w-full max-w-[485px] flex flex-col gap-4">
      <div className="flex justify-between">
       <p className="text-base w-full font-medium text-[#8585A3]">Email </p>
       <span className="self-start text-[#2C2C3A] w-full">{admin?.email || "_"}</span>
      </div>
      <div className="flex justify-between">
       <p className="text-base w-full font-medium text-[#8585A3]">Phone Number </p>
       <span className="text-[#2C2C3A] w-full">{admin?.phone_number || "_"}</span>
      </div>
      <div className="flex justify-between">
       <p className="text-base w-full font-medium text-[#8585A3]">Gender </p>
       <span className="text-[#2C2C3A] w-full">{admin?.gender || "_"}</span>
      </div>
      <div className="flex justify-between">
       <p className="text-base w-full font-medium text-[#8585A3]">Date Added </p>
       <span className="text-[#2C2C3A] w-full">{moment(admin?.created_at).format("MMM DD, YYYY")}</span>
      </div>
      <div className="flex justify-between">
       <p className="text-base w-full font-medium text-[#8585A3]">Number of permissions </p>
       <span className="text-[#2C2C3A] w-full">{admin?.permissions?.length}</span>
      </div>
      <div className="flex justify-between">
       <p className="text-base w-full font-medium text-[#8585A3]">Status</p>
       <span className={`font-medium w-full capitalize ${statusColor[admin?.status]}`}>{admin?.status}</span>
      </div>
     </div>
    </div>
    <div className="flex-1">
     <div className="w-full md:w-[80%] mt-[20px] ml-auto md:mt-0">
      <div className="flex items-center gap-x-5 justify-between">
       <p className="bold__text__24 !text-[20px] !text-[#191716]">Permissions</p>
       <InputField
        placeholder="Search For Permissions"
        bg="#FFF"
        className="!mt-0"
        value={search}
        onChange={({ target }) => setSearch(target.value)}
       />
      </div>
      <div className="mt-[30px] flex flex-col items-start gap-[8px] px-[14px]">
       <FormControlLabel
        onChange={handleSwitchAll}
        value="Disabled"
        control={<Checkbox checked={rolesList?.every((role) => role.value)} />}
        label={`${rolesList?.every((role) => role.value) ? "Revoke" : "Allow"} all permissions`}
       />
       <FormGroup
        sx={{
         mt: "10px",
         display: "flex",
         flexDirection: "row",
         rowGap: "25px",
         columnGap: "20px"
        }}
       >
        {allPermissionsLoading
         ? [1, 2, 3, 4, 5].map((i) => <Skeleton key={i} variant="rounded" width={500} height={35} />)
         : filteredRolesList?.map(({ label, value, id }) => (
            <FormControlLabel
             key={id}
             control={
              <ToggleSwitch
               // parentClass="ml-32 md:ml-52"
               sx={{ me: 2 }}
               toggleState={roleInfo?.data?.name === "Super Admin" ? true : value}
               disabled={roleInfo?.data?.name === "Super Admin"}
               setToggleState={() => (value ? revoke(label) : assign(label))}
              />
             }
             label={label}
             labelPlacement="end"
            />
           ))}
       </FormGroup>
       <LoadingButton
        loading={isAssigning || isRevokingAllPermission || isRevoking}
        loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={24} />}
        onClick={handleUpdatePermission}
        variant="contained"
        sx={{ mt: 2 }}
       >
        Save Permission Changes
       </LoadingButton>
      </div>
     </div>
    </div>
   </div>

   <DeleteAdminModal openModal={openDeleteModal} setopenModal={setopenDeleteModal} />
   <SuspendAdminModal id={id} openModal={openSuspendModal} setopenModal={setopenSuspendModal} />
   <AddNewAdminModal
    openAdminModal={showEditModal}
    setOpenAdminModal={setShowEditModal}
    isEditing={true}
    details={admin}
   />
  </div>
 );
};

export default EachAdministrator;
const statusColor = {
 active: "text-success",
 suspended: "text-red-500",
};
