import React, { useState, useEffect, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import InputField from "./InputField"; // Your custom input component
import { useDebouncedCallback } from "use-debounce";
import "./style.scss";
import { CircularProgress } from "@mui/material";
import { GOOGLE_PLACES_API_KEY } from "utils/constants";
import { getRect } from "hooks/useRect";

function disableScroll() {
 document.body.style.overflow = "hidden";
 document.body.style.userSelect = "none";
}

function enableScroll() {
 document.body.style.overflow = "auto";
 document.body.style.userSelect = "auto";
}

// Load Google Maps Script
const loadGoogleMapsScript = (googleApiKey) => {
 return new Promise((resolve, reject) => {
  if (window.google && window.google.maps) {
   return resolve(window.google.maps);
  }

  if (document.querySelector(`#google-maps-script`)) {
   return reject(new Error("Google Maps script is already being loaded"));
  }

  const script = document.createElement("script");
  script.id = "google-maps-script";
  script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
  script.async = true;
  script.defer = true;

  script.onload = () => {
   resolve(window.google.maps);
  };

  script.onerror = (error) => {
   reject(new Error("Error loading Google Maps API: " + error));
  };

  document.head.appendChild(script);
 });
};

// Google Places Autocomplete Input with Debounce
const GooglePlacesAutocompleteInput = ({
 name,
 label,
 required = true,
 googleApiKey = GOOGLE_PLACES_API_KEY,
 className = "",
 defaultValue = "",
 onChange,
 ...props
}) => {
 const {
  control,
  formState: { errors },
  setValue,
 } = useFormContext();
 const error = errors[name];
 const [styles, setstyles] = useState({
  top: "0px",
  left: "0px",
  width: "0px",
 });

 const [googleMaps, setGoogleMaps] = useState(null);
 const [inputValue, setInputValue] = useState(defaultValue || "");
 const [options, setOptions] = useState([]);
 const [loading, setLoading] = useState(false);
 const [dropdownOpen, setDropdownOpen] = useState(false);

 const parentRef2 = useRef(null);
 const ref = useRef();

 // remove dropdown when outside box is clicked
 useEffect(() => {
  const checkIfClickedOutside = (e) => {
   if (dropdownOpen && ref.current && !ref.current?.contains(e.target)) {
    setDropdownOpen(false);
    enableScroll();
   }
  };
  document.addEventListener("mousedown", checkIfClickedOutside);
  return () => {
   document.removeEventListener("mousedown", checkIfClickedOutside);
  };
 }, [dropdownOpen]);

 // Load Google Maps Script only once
 useEffect(() => {
  if (googleApiKey) {
   loadGoogleMapsScript(googleApiKey)
    .then((maps) => setGoogleMaps(maps))
    .catch((err) => console.error("Error loading Google Maps:", err));
  }
 }, [googleApiKey]);

 // Fetch Google Places predictions
 const fetchGooglePlacesPredictions = async (inputValue) => {
  if (!googleMaps || !inputValue) return;

  setLoading(true);

  const service = new googleMaps.places.AutocompleteService();
  service.getPlacePredictions(
   { input: inputValue, types: ["address"], componentRestrictions: { country: "NG" } },
   (predictions, status) => {
    setLoading(false);
    if (status === googleMaps.places.PlacesServiceStatus.OK) {
     setOptions(
      predictions.map((pred) => ({
       label: pred.description,
       value: pred.description,
      })),
     );
     let rect = getRect(parentRef2.current);
     let top = Math.round(rect.top) + Math.round(rect.height + 10);
     setstyles({
      top: window.innerHeight - 270 > top ? top + "px" : top - 320 + "px",
      left: Math.round(rect.left) + "px",
      width: rect.width + "px",
     });
     disableScroll();
     setDropdownOpen(true); // Open the dropdown when we get options
    } else {
     setOptions([]);
     setDropdownOpen(false); // Close the dropdown if no options
    }
   },
  );
 };

 // Debounced version of the fetch function
 const debouncedFetch = useDebouncedCallback(fetchGooglePlacesPredictions, 500);

 // Handle Input Change
 const handleInputChange = (e) => {
  const value = e.target.value;
  setInputValue(value);
  debouncedFetch(value); // Call the debounced function
  setValue(name, value); // Ensure React Hook Form tracks the input change
 };

 // Handle Option Select
 const handleOptionSelect = (option) => {
  setInputValue(option.value);
  setValue(name, option.value); // Update the form value with selected option
  setOptions([]);
  setDropdownOpen(false);
 };

 const handleBlur = (e) => {
  if (!inputValue || options.length === 0) {
   setDropdownOpen(false);
  }
 };

 useEffect(() => {
  if (options.length > 0 && inputValue) {
   setDropdownOpen(true);
  }
 }, [options, inputValue]);

 return (
  <div className="form-group-wrapper relative" ref={parentRef2}>
   {/* Input Field with Validation */}
   <Controller
    control={control}
    name={name}
    render={({ field }) => (
     <InputField
      {...field}
      {...props}
      label={label}
      value={inputValue}
      isInvalid={error}
      onInput={handleInputChange} // Ensure the custom input updates form state
      onBlur={(e) => {
       field.onBlur(e); // Trigger React Hook Form's onBlur
       handleBlur(e); // Trigger custom onBlur logic
      }}
      onChange={handleInputChange} // Bind the onChange to react hook form
      suffixIcon={loading ? <CircularProgress variant="primary" /> : ""}
     />
    )}
    rules={{
     required: required ? "This field is required" : false,
    }}
   />

   {/* Dropdown for Google Places Predictions */}
   {dropdownOpen && inputValue && options.length > 0 && (
    <div
     style={{ ...styles, zIndex: 4000 }}
     className="rounded fixed bg-white shadow-xl py-2 border border-gray-100
    pointer-events-auto"
     ref={ref}
    >
     {loading ? (
      <div>Loading...</div>
     ) : (
      options.map((option, index) => (
       <div
        className="py-1 px-2 hover:bg-gray-200 rounded bg-opacity-25 cursor-pointer"
        key={index}
        onClick={() => handleOptionSelect(option)}
       >
        {option.label}
       </div>
      ))
     )}
    </div>
   )}
   {/* Error message */}
   {error && <div className="input-err-msg">{error.message}</div>}
  </div>
 );
};

export default GooglePlacesAutocompleteInput;
