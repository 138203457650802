import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "utils/constants";

const baseQuery = fetchBaseQuery({
 baseUrl: API_URL,
 prepareHeaders: (headers, { getState }) => {
  headers.set("Accept", `application/json`);
  const token = getState().auth.token;
  if (token) headers.append("authorization", `Bearer ${token}`);
  return headers;
 },
});

// auth api mutations and query
/* 
  @params: type = buyer || influencer
*/

export const authApi = createApi({
 baseQuery,
 reducerPath: "authApi",
 tagTypes: ["user"],
 endpoints: (builder) => ({
  login: builder.mutation({
   query: (body) => ({
    url: `/admin/auth/login`,
    method: "POST",
    body,
   }),
  }),
  forgotPwd: builder.mutation({
   query: (body) => ({
    url: `/admin/auth/send-password-reset-link`,
    method: "POST",
    body,
   }),
  }),
  resetPwd: builder.mutation({
   query: (body) => ({
    url: `/admin/auth/reset-password`,
    method: "POST",
    body,
   }),
  }),
  getProductCategories: builder.query({
   query: () => "/product-categories",
  }),
 }),
});

export const { 
    useLoginMutation,
    useForgotPwdMutation,
    useResetPwdMutation,
    useGetProductCategoriesQuery 
} = authApi;
