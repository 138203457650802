import {Link, useMatch} from "react-router-dom";

// custom link wrapper using the useMatch to determine the active side menu
export default function CustomLink({
  children,
  to,
  baseUrl = "/dashboard",
  border,
  ...props
}) {
  let match = useMatch(to !== baseUrl ? baseUrl + to + "/*" : baseUrl);
  return to ? (
    <Link
      className={`menu-link ${border && "border-b border-[#FFFFFF26]"} my-4 ${
        match
          ? "bg-white text-secondary"
          : "text-[#D0D0EF] hover:bg-[#d3d3f031]"
      }`}
      to={to || ""}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`menu-link w-full ${
        border && "border-b border-[#FFFFFF26]"
      } my-7 text-[#D0D0EF] hover:bg-[#d3d3f031]`}
      {...props}
    >
      {children}
    </button>
  );
}

export function CustomBuyerLink({children, to, baseUrl = "/buyer/", ...props}) {
  let match = useMatch(to !== baseUrl ? baseUrl + to + "/*" : baseUrl);
  return (
    <Link
      className={`p-2 px-4 rounded-lg text-white ${
        match ? "font-semibold bg-[#d3d3f031]" : "font-medium"
      }`}
      to={to || ""}
      {...props}
    >
      {children}
    </Link>
  );
}
