import { useFormContext, get } from "react-hook-form";
import InputField from "./InputField";
import { removeCommas } from "utils";

function ValidatedInput({
 name,
 required = true,
 rules,
 disabled = false,
 errMsg,
 showErrMsg = true,
 onChange,
 value,
 validateDiscount = false, 
 step = "1",  
 min = "1",
 max = "10",
 type = "text", 
 ...otherProps
}) {
 const {
  register,
  formState: { errors },
  getValues,
  setValue,
 } = useFormContext();

 const error = get(errors, name);

 const handlePriceChange = (e) => {
  const inputValue = e.target.value;
  let rawValue = inputValue.replace(/[^0-9]/g, "");

  if (rawValue === "") {
   setValue(name, undefined);
   e.target.value = "";
  } else {
   let formattedValue = new Intl.NumberFormat().format(rawValue);
   setValue(name, rawValue);
   e.target.value = formattedValue;
  }

  if (onChange) onChange(e);
 };

 const handleWeightChange = (e) => {
  let inputValue = e.target.value;
  let rawValue = inputValue.replace(/[^0-9.]/g, "");

  if (rawValue === "") {
   setValue(name, undefined);
   e.target.value = "";
  } else {
   if (rawValue.split(".").length > 2) {
    rawValue = rawValue.slice(0, -1);
   }

   setValue(name, rawValue);
   e.target.value = rawValue;
  }

  if (onChange) onChange(e);
 };

 const handleCommissionChange = (e) => {
  const inputValue = e.target.value;
  let rawValue = inputValue.replace(/[^0-9]/g, "");

  if (rawValue === "") {
   setValue(name, undefined);
   e.target.value = "";
  } else {
   setValue(name, rawValue);
   e.target.value = rawValue;
  }

  if (onChange) onChange(e);
 };

 const discountValidation = validateDiscount
  ? {
     validate: {
      greaterThanOrEqualToPrice: (value) => {
       const price = getValues("price");
       if (parseFloat(value) > parseFloat(price)) {
        return "Discount must be less than or equal to Price";
       }
       return true;
      },
     },
    }
  : {};

 const inputProps = {
  ...otherProps,
  ...register(name, {
   required: required ? "This field is required" : false,
   ...(name.includes("weight")
    ? {
       min: {
        value: 0.01,
        message: "Weight must be greater than 0.01",
       },
      }
    : {}),
   ...(name.includes("variants") && name.includes("price")
    ? {
       validate: {
        compareWithDiscount: (value) => {
         const hasDiscount = getValues("discount") !== null;
         if (hasDiscount) {
          const discount = removeCommas(getValues("discount"));
          const inputValue = removeCommas(value);
          if (parseFloat(discount) > parseFloat(inputValue)) {
           return false;
          }
         }
        },
       },
      }
    : {}),
   ...(name.includes("commission_rate")
    ? {
       validate: {
        isGreaterThanOne: (value) => value >= 1 || "Commission rate must be between 1-100",
        isLessThan100: (value) => value <= 100 || "Commission rate must be between 1-100",
       },
      }
    : {}),
   ...(type === "password"
    ? {
       minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
       },
       pattern: {
        value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
        message: "Password must have at least 1 uppercase, lowercase, number and a symbol",
       },
      }
    : {}),
   ...rules,
   ...discountValidation,
  }),
  onChange:
   type === "text" && (name.includes("price") || name === "discount" || name.includes("quantity"))
    ? handlePriceChange
    : name.includes("weight")
    ? handleWeightChange
    : name === "commission_rate"
    ? handleCommissionChange
    : onChange,
 };

 if (type === "number") {
  inputProps.step = step;
  inputProps.min = min;
  inputProps.max = max;
 }

 return (
  <div className="form-group-wrapper">
   <InputField 
      type={type}
      disabled={disabled}
      value={value}
      isInvalid={error} 
      {...inputProps} 
   />
   {showErrMsg && error && <div className="input-err-msg">{error.message || errMsg}</div>}
  </div>
 );
}

export default ValidatedInput;
