import { Avatar } from "@mui/material";

function ProductDisplay({
 name,
 img,
 imgRounded = true,
 truncate = false,
 max_width = "100%",
 size = "medium",
 textWhite = false,
 reverse = false,
 className = "",
 onClick,
}) {
 return (
  <div
   className={`flex ${size === "small" ? "gap-x-2" : "gap-x-4"} items-center ${
    reverse && "flex-row-reverse"
   } ${className}`}
  >
   <div className="relative h-[40px] w-[40px]">
    <Avatar
     src={img}
     alt="avatar"
     className={`w-full ${onClick && "cursor-pointer"}`}
     sx={{
      borderRadius: imgRounded ? "50%" : "4px",
      width: size === "medium" ? 40 : size === "small" ? 30 : 50,
      height: size === "medium" ? 40 : size === "small" ? 30 : 50,
     }}
     onClick={onClick}
    />
   </div>
   <div>
    <p
     style={{ maxWidth: max_width }}
     className={`${truncate && `truncate`} ${size === "medium" || size === "small" ? "text-sm" : "text-base"} ${
      textWhite ? "text-white" : "text-secondary"
     }  font-medium capitalize`}
    >
     {name}
    </p>
   </div>
  </div>
 );
}

export default ProductDisplay;
