import { useState, useEffect } from "react";
import { Button, Chip, IconButton } from "@mui/material";
import StatCard from "components/StatCard";
import MyDataTable from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import { Link, useParams } from "react-router-dom";
import { STATUS } from "utils/constants";
import moment from "moment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VideoCameraIcon from "components/Vectors/VideoCameraIcon";
import { useGetClientProductsQuery, useGetClientProductsStatsQuery, useGetSingleClientQuery } from "services";
import { getUserDetails, handleError, toCurrency } from "utils";
import ProductDisplay from "components/TableComponent/ProductDisplay";
import EditIcon from "components/Vectors/EditIcon";
import BackBtn from "components/BackBtn";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import BagIcon from "components/Vectors/BagIcon";
//import { useLazySearchUserQuery } from "services/search.api";

const ClientProducts = () => {
 const PRODUCTS_PER_PAGE = 10;
 const [page, setPage] = useState(1);
 const { id } = useParams();
 const { data: clientData } = useGetSingleClientQuery(id);

 const [perPage, setPerPage] = useState(PRODUCTS_PER_PAGE);
 const {
  data: allClientProducts,
  isLoading,
  error,
 } = useGetClientProductsQuery({
  params: { is_paginated: "yes", page, per_page: perPage },
  id,
 });
 const [clientProducts, setClientProducts] = useState([]);

 useEffect(() => {
  if (allClientProducts?.data?.data) {
   const initialProducts = allClientProducts?.data?.data?.filter((product) => product);
   setClientProducts(initialProducts);
  }
 }, [allClientProducts]);
 console.log(clientProducts);
 const searchFunc = (value) => {
  const matchedProducts = allClientProducts?.data?.data?.filter((product) =>
   product?.name?.toLowerCase().includes(value.toLowerCase()),
  );
  setClientProducts(matchedProducts);
 };

 const { data: allClientProductsStats } = useGetClientProductsStatsQuery(id);

 const options = {
  searchOpen: true,
  viewColumns: false,
  filter: true,
  selectableRows: "none",
  count: allClientProducts?.data?.total,
  rowsPerPage: perPage,
  serverSide: true,
  onTableChange: (action, tableState) => {
   switch (action) {
    case "changePage":
     setPage(tableState.page + 1);
     break;
    case "changeRowsPerPage":
     setPerPage(tableState.rowsPerPage);
     break;
    default:
     break;
   }
  },
 };

 // if (allClientProducts) console.log(allClientProducts)

 const statCards = [
  {
   name: "All Brand Products",
   value: allClientProductsStats?.data?.clients?.total,
   iconBg: "bg-[#FAF5FF]",
   icon: <BagIcon width={32} height={32} color="#8937CE" />,
   percentage: null,
  },
  {
   name: "All Verified Brand Products",
   value: allClientProductsStats?.data?.clients?.verified,
   iconBg: "bg-success-100",
   icon: <BagIcon width={32} height={32} color="#00A04A" />,
   percentage: null,
  },
  {
   name: "All Unverified Brand Products",
   value: allClientProductsStats?.data?.clients?.unverified,
   iconBg: "bg-gray-100",
   icon: <BagIcon width={32} height={32} color="#8585A3" />,
   percentage: null,
  },
 ];

 const headCells = [
  {
   name: "id",
   label: <p className="truncate flex text-center first-letter:font-medium text-prop text-sm">ID</p>,
   options: {
    customBodyRender: (value) => <p className="w-full ml-[-5px] text-center ">{value}</p>,
   },
  },
  {
   name: "product",
   label: "product name",
   options: {
    customBodyRender: (value) => (
     <Link to={null}>
      <ProductDisplay name={value.name} img={value.img} />
     </Link>
    ),
   },
  },
  {
   name: "category",
   label: "Parent Category",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-[14px]">{value}</p>,
   },
  },
  {
   name: "price",
   label: "Price",
  },
  {
   name: "discount",
   label: "Sale Price",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-[14px]">{value}</p>,
   },
  },
  {
   name: "stock",
   label: "Stock",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-[14px]">{value}</p>,
   },
  },
  {
   name: "status",
   label: "Status",
   options: {
    filter: true,
    customBodyRender: (value) => (
     <Chip
      color={`${STATUS[value?.toLowerCase()] || "default"}`}
      sx={{ minWidth: 100, borderRadius: "20px" }}
      label={`${value}`}
     />
    ),
   },
  },

  {
   name: "actions",
   label: "actions",
   options: {
    customBodyRender: (value) => (
     <>
      <IconButton to={`/brands/${id}/edit-product/${value?.edit}`} component={Link}>
       <EditIcon />
      </IconButton>
      {/* <IconButton to={value?.view} component={Link}>
       <RemoveRedEyeOutlinedIcon />
      </IconButton> */}
     </>
    ),
   },
  },
 ];

 const data = clientProducts?.map((product, i) => ({
  // (current_page - 1) * per_page + number of data entries on the current page
  id: (allClientProducts?.data?.current_page - 1) * allClientProducts?.data?.per_page + (i + 1),
  product: {
   id: String(product?.id),
   name: product?.name,
   img: product?.media[0]?.original_url,
  },
  category: product?.product_category?.name,
  price: toCurrency(product?.price),
  discount: toCurrency(product?.discount),
  stock: product?.quantity,
  status: product?.status,
  actions: {
   edit: String(product?.id),
   view: String(product?.id),
  },
 }));

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <BackBtn className="mb-0" />
   <div className="flex justify-between items-center mb-4">
    {clientData && (
     <h1 className="title">
      {(clientData?.data?.business_name
       ? clientData?.data?.business_name
       : clientData?.data?.first_name + " " + clientData?.data?.last_name) + " Products"}
     </h1>
    )}
   </div>

   {/* Cards */}
   <div className="grid-cols-2 lg:grid-cols-4 grid gap-4">
    {statCards.map((statCard, index) => (
     <StatCard ke ={index} {...statCard} />
    ))}
   </div>

   {/* Table */}
   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8"></div>
   <div className="flex flex-col md:flex-row gap-5 md:justify-between md:items-center mt-8">
    <h5 className="text-2xl font-semibold">Products</h5>
    <Link to={`/brands/add-product/${id}`}>
      <Button variant="contained" startIcon={<ControlPointIcon />}>
        Add Product
      </Button>
    </Link>
   </div>
   <div className="p-4 bg-white py-6 border rounded-xl mt-5">
    <MyDataTable
     options={options}
     headCells={headCells}
     isLoading={isLoading}
     error={error}
     searchFunc={searchFunc}
     data={data}
     emptyData={{
      icon: VideoCameraIcon,
      message: "No Products",
      extraText: "There are no products from this brand.",
     }}
     searchPlaceholder="Search Brand Products..."
    />
   </div>
  </div>
 );
};

export default ClientProducts;
