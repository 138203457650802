import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setUserDetails } from "store/slice/AuthSlice";
import { showToast } from "store/store.hooks";
import { API_URL } from "utils/constants";

const baseQuery = fetchBaseQuery({
 baseUrl: `${API_URL}/admin/`,
 prepareHeaders: (headers, api) => {
  const { auth } = api.getState();
  if (auth.token) {
   headers.set("authorization", `Bearer ${auth.token}`);
   headers.set("Access-Control-Allow-Origin", `*`);
  }
  headers.set("Accept", `application/json`);
  return headers;
 },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
 let result = await baseQuery(args, api, extraOptions);
 let err = result?.error;
 if (result?.error && err?.data?.message === "Unauthenticated." && err.status === 401) {
  // logout
  showToast("Something went wrong, Please login again", "error");
  setTimeout(() => {
   api.dispatch(
    logOut({
     redirect: true,
    }),
   );
  }, 1000);
 }
 return result;
};

export const generalApi = createApi({
 baseQuery: baseQueryWithReauth,
 reducerPath: "generalApi",
 tagTypes: [
  "userData",
  "category",
  "skill",
  "socials",
  "roles",
  "categoryData",
  "skillData",
  "tagData",
  "location",
  "referral",
  "bannedWord",
  "socialData",
  "serviceChargeOptions",
  "users",
  "promotions",
  "services",
  "permissions",
  "clients",
  "creators",
  "tickets",
  "campaignData",
  "profile",
  "notifications",
 ],
 endpoints: (builder) => ({
  getCountries: builder.query({
   query: () => "/location/countries",
  }),
  getStateByCountry: builder.query({
   query: (countryId) => `/location/states/${countryId}`,
  }),
  getCitiesByState: builder.query({
   query: (stateId) => `/location/cities/${stateId}`,
  }),
  // admin profile
  updateProfile: builder.mutation({
   query: (body) => ({
    url: "update-profile",
    method: "POST",
    body,
   }),
   invalidatesTags: ["userData"],
  }),
  getUserReviewsReceived: builder.query({
   query: ({ id }) => `/reviews-received/${id}`,
   providesTags: ["profile"],
  }),
  getUserReviewsGiven: builder.query({
   query: ({ id }) => `/reviews-given/${id}`,
   providesTags: ["profile"],
  }),
  validateUserName: builder.mutation({
   query: ({ body }) => ({
    url: `/check-username`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["profile"],
  }),
  updatePhoto: builder.mutation({
   query: (body) => ({
    url: "update-profile-picture",
    method: "POST",
    body,
   }),
   invalidatesTags: ["userData"],
  }),
  removePhoto: builder.mutation({
   query: (body) => ({
    url: "remove-profile-picture",
    method: "POST",
    body,
   }),
   invalidatesTags: ["userData"],
  }),
  changePassword: builder.mutation({
   query: (body) => ({
    url: `change/password`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["userData"],
  }),
  getLoggedInUser: builder.query({
   query: (token) => ({
    url: `profile`,
    headers: {
     Authorization: `Bearer ${token}`,
    },
   }),
   async onCacheEntryAdded(id, { dispatch, cacheDataLoaded }) {
    try {
     const { data } = await cacheDataLoaded;
     dispatch(setUserDetails(data.data));
    } catch (err) {}
   },
   providesTags: ["userData"],
  }),

  // dashboard data
  getDashboardData: builder.query({
   query: () => ({
    url: "dashboard",
   }),
   providesTags: ["dashboard"],
  }),

  getDashboardStats: builder.query({
   query: () => ({
    url: `dashboard/stats`,
   }),
   providesTags: ["dashboard"],
  }),

  // generic routes
  genericDelete: builder.mutation({
   query: ({ route }) => ({
    url: `${route}`,
    method: "DELETE",
   }),
   invalidatesTags: ["roles"],
  }),
  genericEditRoute: builder.mutation({
   query: ({ body, route }) => ({
    url: `${route}`,
    method: "PATCH",
    body,
   }),
   invalidatesTags: ["roles"],
  }),

  // roles and permissions
  createRoles: builder.mutation({
   query: (body) => ({
    url: `roles`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["roles"],
  }),
  getRoles: builder.query({
   query: () => ({
    url: `roles`,
   }),
   providesTags: ["roles"],
  }),
  getEachRole: builder.query({
   query: (id) => `roles/${id}`,
   providesTags: ["roles"],
  }),
  getPermissions: builder.query({
   query: () => `modules`,
   providesTags: ["roles"],
  }),
  addPermissions: builder.mutation({
   query: ({ modules, id }) => ({
    url: `roles/${id}/modules`,
    method: "POST",
    body: {
     modules,
    },
   }),
   invalidatesTags: ["roles"],
  }),
  getAdmins: builder.query({
   query: () => "list",
   providesTags: ["roles"],
  }),
  getEachAdmin: builder.query({
   query: (id) => `show/${id}`,
   providesTags: ["roles"],
  }),
  addAdmin: builder.mutation({
   query: (body) => ({
    url: "/add-admin",
    method: "POST",
    body,
   }),
   invalidatesTags: ["roles"],
  }),
  editAdmin: builder.mutation({
   query: ({ adminId, body }) => ({
    url: `update-${adminId}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["roles"],
  }),
  assignAdminRole: builder.mutation({
   query: ({ body, id }) => ({
    url: `${id}/reassign-role`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["roles"],
  }),
  suspendAdmin: builder.mutation({
   query: ({ id, body }) => ({
    url: `suspend-${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["roles"],
  }),
  unSuspendAdmin: builder.mutation({
   query: (id) => ({
    url: `unsuspend-${id}`,
    method: "POST",
   }),
   invalidatesTags: ["roles"],
  }),
  deleteAdmin: builder.mutation({
   query: ({ id, body }) => ({
    url: `delete-${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["roles"],
  }),
  // Roles and permission
  getAllPermission: builder.query({
   query: () => "permission/list",
   providesTags: ["permissions"],
  }),
  getSinglePermission: builder.query({
   query: (id) => `permission/show/${id}`,
   providesTags: ["permissions"],
  }),
  assignPermission: builder.mutation({
   query: (body) => ({
    url: "permission/give-direct-permission",
    method: "POST",
    body,
   }),
   invalidatesTags: ["permissions", "roles"],
  }),
  revokePermission: builder.mutation({
   query: (body) => ({
    url: "permission/revoke-admin-permission",
    method: "POST",
    body,
   }),
   invalidatesTags: ["permissions", "roles"],
  }),
  revokeAllPermissions: builder.mutation({
   query: (body) => ({
    url: `permission/revoke-multiple-admin-permissions`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["permissions", "roles"],
  }),
  // settings
  getAllCategories: builder.query({
   query: () => "category/list",
   providesTags: ["categoryData"],
  }),
  getSingleCategory: builder.query({
   query: (id) => `category/show/${id}`,
   providesTags: ["categoryData"],
  }),
  getCategoryByUsertype: builder.query({
   query: (userType) => `setting/category/user-type/${userType}`,
   providesTags: ["categoryData"],
  }),
  addCategory: builder.mutation({
   query: (body) => ({
    url: "category/create",
    method: "POST",
    body,
   }),
   invalidatesTags: ["categoryData"],
  }),
  updateCategory: builder.mutation({
   query: ({ id, body }) => ({
    url: `category/update/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["categoryData"],
  }),
  activateOrSuspendCategory: builder.mutation({
   query: (id) => ({
    url: `category/update-status/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["categoryData"],
  }),
  deleteSingleCategory: builder.mutation({
   query: (id) => ({
    url: `category/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["categoryData"],
  }),
  // settings sub-category
  getCategorySubCategories: builder.query({
   query: (id) => `setting/sub-category/category/${id}`,
   providesTags: ["categoryData"],
  }),
  addSubCategory: builder.mutation({
   query: (body) => ({
    url: "sub-categories/add",
    method: "POST",
    body,
   }),
   invalidatesTags: ["categoryData"],
  }),
  deleteSingleSubCategory: builder.mutation({
   query: (id) => ({
    url: `sub-categories/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["categoryData"],
  }),
  updateSubCategory: builder.mutation({
   query: (body) => ({
    url: "sub-categories/edit",
    method: "POST",
    body,
   }),
   invalidatesTags: ["categoryData"],
  }),
  activateOrSuspendSubCategory: builder.mutation({
   query: (id) => ({
    url: `setting/sub-category/update-status/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["categoryData"],
  }),
  // settings skills
  getAllSkills: builder.query({
   query: () => `setting/skills`,
   providesTags: ["skillData"],
  }),
  getSingleSkill: builder.query({
   query: (id) => `setting/skills/${id}`,
   providesTags: ["skillData"],
  }),
  getSkillsByUsertype: builder.query({
   query: (userType) => `setting/skill/user/${userType}`,
   providesTags: ["skillData"],
  }),
  activateOrDeactivateSKill: builder.mutation({
   query: (id) => ({
    url: `setting/skill/update-status/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["skillData"],
  }),
  addSkill: builder.mutation({
   query: (body) => ({
    url: `setting/skills`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["skillData"],
  }),
  updateSkill: builder.mutation({
   query: ({ id, body }) => ({
    url: `setting/skills/${id}`,
    method: "PUT",
    body,
   }),
   invalidatesTags: ["skillData"],
  }),
  deleteSkill: builder.mutation({
   query: (id) => ({
    url: `setting/skills/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["skillData"],
  }),
  // settings tags
  getAllTags: builder.query({
   query: () => `setting/tags`,
   providesTags: ["tagData"],
  }),
  getSingleTag: builder.query({
   query: (id) => `setting/tags/${id}`,
   providesTags: ["tagData"],
  }),
  addTag: builder.mutation({
   query: (body) => ({
    url: `setting/tags`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["tagData"],
  }),
  updateTag: builder.mutation({
   query: ({ id, body }) => ({
    url: `setting/tags/${id}`,
    method: "PUT",
    body,
   }),
   invalidatesTags: ["tagData"],
  }),
  deleteTag: builder.mutation({
   query: (id) => ({
    url: `setting/tags/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["tagData"],
  }),
  // banned words
  addBannedWord: builder.mutation({
   query: (body) => ({
    url: "banned-word/add",
    method: "POST",
    body,
   }),
   invalidatesTags: ["bannedWord"],
  }),
  getAllBannedWords: builder.query({
   query: () => "banned-word/list",
   providesTags: ["bannedWord"],
  }),
  deleteBannedWord: builder.mutation({
   query: (id) => ({
    url: `banned-word/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["bannedWord"],
  }),
  // referral settings
  getAllReferralSetting: builder.query({
   query: () => "referral-setting/list",
   providesTags: ["referral"],
  }),
  getInfluencerReferralSetting: builder.query({
   query: () => "influencer-referral-setting/list",
   providesTags: ["referral"],
  }),
  updateReferralSetting: builder.mutation({
   query: ({ id, body }) => ({
    url: `referral-setting/update-option/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["referral"],
  }),
  updateInfluencerReferralSetting: builder.mutation({
   query: ({ id, body }) => ({
    url: `influencer-referral-setting/update-option/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["referral"],
  }),
  enableOrDisableReferralSetting: builder.mutation({
   query: (id) => ({
    url: `referral-setting/update-status/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["referral"],
  }),
  enableOrDisableInfluencerReferralSetting: builder.mutation({
   query: (id) => ({
    url: `influencer-referral-setting/update-status/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["referral"],
  }),
  // Location settings
  getAllCountries: builder.query({
   query: () => "country/list",
   providesTags: ["location"],
  }),
  getOneCountry: builder.query({
   query: (id) => `country/show/${id}`,
   providesTags: ["location"],
  }),
  addCountry: builder.mutation({
   query: (body) => ({
    url: "country/create",
    method: "POST",
    body,
   }),
   invalidatesTags: ["location"],
  }),
  editCountry: builder.mutation({
   query: ({ id, body }) => ({
    url: `country/update/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["location"],
  }),
  deleteCountry: builder.mutation({
   query: (id) => ({
    url: `country/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["location"],
  }),
  getStatesByCountry: builder.query({
   query: (id) => `state/country/${id}`,
   providesTags: ["location"],
  }),
  getSingleState: builder.query({
   query: (id) => `states/show/${id}`,
   providesTags: ["location"],
  }),
  addState: builder.mutation({
   query: (body) => ({
    url: "state/create",
    method: "POST",
    body,
   }),
   invalidatesTags: ["location"],
  }),
  editState: builder.mutation({
   query: ({ id, body }) => ({
    url: `state/update/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["location"],
  }),
  suspendState: builder.mutation({
   query: (id) => ({
    url: `state/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["location"],
  }),
  deleteState: builder.mutation({
   query: (id) => ({
    url: `states/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["location"],
  }),
  getCityByState: builder.query({
   query: (id) => `city/state/${id}`,
   providesTags: ["location"],
  }),
  addCity: builder.mutation({
   query: (body) => ({
    url: "city/create",
    method: "POST",
    body,
   }),
   invalidatesTags: ["location"],
  }),
  editCity: builder.mutation({
   query: ({ id, body }) => ({
    url: `city/update/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["location"],
  }),
  suspendCity: builder.mutation({
   query: (body) => ({
    url: "cities/suspend-city",
    method: "POST",
    body,
   }),
   invalidatesTags: ["location"],
  }),
  deleteCity: builder.mutation({
   query: ({ cityId, stateId }) => ({
    url: `city/delete/${stateId}/${cityId}`,
    method: "DELETE",
   }),
   invalidatesTags: ["location"],
  }),
  // settings socials
  addSocial: builder.mutation({
   query: (body) => ({
    url: `setting/socials`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["socialData"],
  }),
  updateSocial: builder.mutation({
   query: ({ id, body }) => ({
    url: `setting/socials/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["socialData"],
  }),
  getAllSocials: builder.query({
   query: () => `setting/socials`,
   providesTags: ["socialData"],
  }),
  deleteSocial: builder.mutation({
   query: (id) => ({
    url: `setting/socials/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["socialData"],
  }),
  activateOrDeactivateSocial: builder.mutation({
   query: (id) => ({
    url: `setting/social/update-status/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["socialData"],
  }),
  // kyc
  approveUserKyc: builder.mutation({
   query: (id) => ({
    url: `kyc/approve-kyc/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["clients", "influencers"],
  }),
  declineUserKyc: builder.mutation({
   query: ({ id, body }) => ({
    url: `kyc/decline-kyc/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["clients", "influencers"],
  }),
  // service charge
  getAllServiceCharge: builder.query({
   query: () => "service-charge/list",
   providesTags: ["serviceChargeOptions"],
  }),
  updateSingleServiceChargeOption: builder.mutation({
   query: ({ id, body }) => ({
    url: `service-charge/update-option/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["serviceChargeOptions"],
  }),
  // Promotions
  getAllPromotion: builder.query({
   query: () => `promotions/packages`,
   providesTags: ["promotions"],
  }),
  createPromotion: builder.mutation({
   query: (body) => ({
    url: "promotions/packages",
    method: "POST",
    body,
   }),
   invalidatesTags: ["promotions"],
  }),
  editPromotion: builder.mutation({
   query: ({ id, body }) => ({
    url: `promotions/packages/${id}`,
    method: "PUT",
    body,
   }),
   invalidatesTags: ["promotions"],
  }),
  getAllCampaignSources: builder.query({
   query: () => "campaign-source/list",
   providesTags: ["campaignData"],
  }),
  getOneCampaignSource: builder.query({
   query: (id) => `campaign-source/show/${id}`,
   providesTags: ["campaignData"],
  }),
  createChannel: builder.mutation({
   query: (body) => ({
    url: "campaign-channel/create",
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaignData"],
  }),
  getOneCampaignChannel: builder.query({
   query: (id) => `campaign-channel/show/${id}`,
   providesTags: ["campaignData"],
  }),
  editChannel: builder.mutation({
   query: ({ id, body }) => ({
    url: `campaign-channel/update/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaignData"],
  }),
  updateChannelStatus: builder.mutation({
   query: (body) => ({
    url: "campaign-channel/update-status",
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaignData"],
  }),
  deleteChannel: builder.mutation({
   query: ({ id, body }) => ({
    url: `campaign-channel/delete/${id}`,
    method: "DELETE",
    body,
   }),
   invalidatesTags: ["campaignData"],
  }),
  createSubTask: builder.mutation({
   query: (body) => ({
    url: "sub-task/create",
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaignData"],
  }),
  editSubTask: builder.mutation({
   query: ({ id, body }) => ({
    url: `sub-task/update/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaignData"],
  }),
  deleteSubTask: builder.mutation({
   query: (id) => ({
    url: `sub-task/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["campaignData"],
  }),
  // buyers section
  getAllUsers: builder.query({
   query: (type) => ({
    url: `users`,
    params: {
     type,
    },
   }),
   providesTags: ["users"],
  }),
  getSingleUserDetail: builder.query({
   query: (id) => `users/${id}`,
   providesTags: ["users"],
  }),
  getUsersStat: builder.query({
   query: (type) => ({
    url: `users-stat`,
    params: {
     type,
    },
   }),
   providesTags: ["users"],
  }),
  suspendUser: builder.mutation({
   query: (body) => ({
    url: `suspend-user`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["users"],
  }),
  activateUser: builder.mutation({
   query: (id) => ({
    url: `users/activate-deactivate/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["users"],
  }),
  // services
  getAllServices: builder.query({
   query: () => `services/list`,
   providesTags: ["services"],
  }),
  getSingleServices: builder.query({
   query: (id) => `services/show/${id}`,
   providesTags: ["services"],
  }),
  getServicesStat: builder.query({
   query: () => `services/statistic`,
   providesTags: ["services"],
  }),
  updateServiceStatus: builder.mutation({
   query: ({ id, body }) => ({
    url: `services/update-status/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["services"],
  }),
  // Clients
  getAllClients: builder.query({
   query: ({ params }) => ({
    url: "client/list",
    params,
   }),
   providesTags: ["clients"],
  }),
  getSingleClient: builder.query({
   query: (id) => `client/show/${id}`,
   providesTags: ["clients"],
  }),
  getSingleClientStats: builder.query({
   query: (id) => `client/stats/${id}`,
   providesTags: ["clients"],
  }),
  createClient: builder.mutation({
   query: (body) => ({
    url: `/client/create`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["clients"],
  }),
  updateClientProfile: builder.mutation({
   query: ({ body, id }) => ({
    url: `/client/update-profile/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["clients"],
  }),
  removeClientProfilePhoto: builder.mutation({
   query: (id) => ({
    url: `/client/remove-profile-picture/${id}`,
    method: "POST",
    body: {},
   }),
   invalidatesTags: ["clients"],
  }),
  suspendClientAccount: builder.mutation({
   query: (body) => ({
    url: "client/suspend-client-account",
    method: "POST",
    body,
   }),
   invalidatesTags: ["clients"],
  }),
  activateClientAccount: builder.mutation({
   query: (id) => ({
    url: `client/activate-account/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["clients"],
  }),
  getClientStats: builder.query({
   query: () => "client/get-client-stats",
   providesTags: ["clients"],
  }),
  getClientProductsStats: builder.query({
   query: (id) => `client/product-stats/${id}`,
   providesTags: ["clients"],
  }),
  getClientProducts: builder.query({
   query: ({ params, id }) => ({
    url: `client/products/${id}`,
    params,
   }),
   providesTags: ["clients"],
  }),
  getSingleClientProduct: builder.query({
   query: ({ clientId, id }) => `client/${clientId}/product/show/${id}`,
   providesTags: ["clients"],
  }),
  createClientProduct: builder.mutation({
   query: (id) => ({
    url: `client/create-product/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["clients"],
  }),
  updateClientProduct: builder.mutation({
   query: ({ id, body }) => ({
    url: `client/edit-product/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["clients"],
  }),
  // Media-specific mutations
  uploadClientProductImage: builder.mutation({
   query: ({ productId, body }) => ({
    url: `client/product/${productId}/media`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["clients"],
  }),
  deleteClientProductImage: builder.mutation({
   query: ({ productId, mediaId }) => ({
    url: `client/product/${productId}/media/${mediaId}`,
    method: "DELETE",
   }),
   invalidatesTags: ["clients"],
  }),
  // Creators
  getAllCreators: builder.query({
   query: ({ params }) => ({
    url: "creator/list",
    params,
   }),
   providesTags: ["creators"],
  }),
  getCreatorsStats: builder.query({
   query: () => "creator/get-creator-stats",
   providesTags: ["creators"],
  }),
  getOneCreator: builder.query({
   query: (creatorId) => `creator/show/${creatorId}`,
   providesTags: ["creators"],
  }),
  getSingleCreatorStats: builder.query({
   query: (creatorId) => `creator/stats/${creatorId}`,
   providesTags: ["creators"],
  }),
  activateCreatorAccount: builder.mutation({
   query: (id) => ({
    url: `creator/activate-account/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["creators"],
  }),
  suspendCreatorAccount: builder.mutation({
   query: (body) => ({
    url: "creator/suspend-creator-account",
    method: "POST",
    body,
   }),
   invalidatesTags: ["creators"],
  }),
  // tickets
  getAllTickets: builder.query({
   query: () => "tickets/list",
   providesTags: ["tickets"],
  }),
  getSingleTicket: builder.query({
   query: (id) => `tickets/show/${id}`,
   providesTags: ["tickets"],
  }),
  getTicketByStatus: builder.query({
   query: ({ status, params }) => ({
    url: `tickets/status/${status}`,
    params,
   }),
   providesTags: ["tickets"],
  }),
  getTicketsStats: builder.query({
   query: () => "tickets/stats",
   providesTags: ["tickets"],
  }),
  updateTicketStatus: builder.mutation({
   query: (body) => ({
    url: "tickets/update-status",
    method: "POST",
    body,
   }),
   invalidatesTags: ["tickets"],
  }),
  // influencers
  getInfluencers: builder.query({
   query: ({ params }) => ({
    url: "influencer/list",
    params,
   }),
   providesTags: ["influencers"],
  }),
  getInfluencerStats: builder.query({
   query: () => "influencer/get-influencer-stats",
   providesTags: ["influencers"],
  }),
  getAllInfluencersByAccessType: builder.query({
   query: ({ status, ...params }) => {
    return {
     url: `influencer/access-type/${status}`,
     params,
    };
   },
   providesTags: ["influencers"],
  }),
  getSingleInfluencerStats: builder.query({
   query: (id) => `influencer/stats/${id}`,
   providesTags: ["influencers"],
  }),
  getOneInfleuncer: builder.query({
   query: (influencerId) => `influencer/show/${influencerId}`,
   providesTags: ["influencers"],
  }),
  activateInfluencerAccount: builder.mutation({
   query: (id) => ({
    url: `influencer/activate-account/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["influencers"],
  }),
  suspendInfluencerAccount: builder.mutation({
   query: (body) => ({
    url: "influencer/suspend-influencer-account",
    method: "POST",
    body,
   }),
   invalidatesTags: ["influencers"],
  }),
  // new users
  getNewUsers: builder.query({
   query: () => "users-with-unverify-email",
   providesTags: ["newUsers"],
  }),
  getPendingVerification: builder.query({
   query: () => "users-pending-kyc-verification",
   providesTags: ["pendingVerification"],
  }),
  getNewVerifiedUsers: builder.query({
   query: () => "users-with-verify-account",
   providesTags: ["pendingVerification"],
  }),

  deleteUser: builder.mutation({
   query: (body) => ({
    url: `/delete-user`,
    method: "POST",
    body,
   }),
   // invalidatesTags: ["campaignData"],
  }),
  unSuspendUser: builder.mutation({
   query: (id) => ({
    url: `unsuspend-admin/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["clients", "influencers"],
  }),

  // notification
  getNotification: builder.query({
   query: ({ params }) => ({
    url: "/in-app-notification/get-all",
    params,
   }),
   providesTags: ["notifications"],
  }),

  markNotificationAsRead: builder.mutation({
   query: (body) => ({
    url: "/in-app-notification/mark-one-as-read",
    method: "POST",
    body,
   }),
   invalidatesTags: ["notifications"],
  }),
  markMultipleAsRead: builder.mutation({
   query: () => ({
    url: "/in-app-notification/mark-multiple-as-read",
    method: "POST",
   }),
   invalidatesTags: ["notifications"],
  }),
 }),
});
export const {
 useGenericDeleteMutation,
 useGetCountriesQuery,
 useGetStateByCountryQuery,
 useGetCitiesByStateQuery,
 useUpdateProfileMutation,
 useUpdatePhotoMutation,
 useGetUserReviewsReceivedQuery,
 useGetUserReviewsGivenQuery,
 useValidateUserNameMutation,
 useRemovePhotoMutation,
 useChangePasswordMutation,
 useGetRolesQuery,
 useCreateRolesMutation,
 useGetPermissionsQuery,
 useGetEachRoleQuery,
 useAddPermissionsMutation,
 useGetAdminsQuery,
 useAddAdminMutation,
 useSuspendAdminMutation,
 useUnSuspendAdminMutation,
 useDeleteAdminMutation,
 useGetEachAdminQuery,
 useGenericEditRouteMutation,
 useAssignAdminRoleMutation,
 useEditAdminMutation,
 useGetLoggedInUserQuery,
 // Roles and permission
 useGetAllPermissionQuery,
 useGetSinglePermissionQuery,
 useAssignPermissionMutation,
 useRevokePermissionMutation,
 useRevokeAllPermissionsMutation,
 // category and sub category
 useGetAllCategoriesQuery,
 useGetSingleCategoryQuery,
 useGetCategoryByUsertypeQuery,
 useAddCategoryMutation,
 useUpdateCategoryMutation,
 useDeleteSingleCategoryMutation,
 useAddSubCategoryMutation,
 useGetCategorySubCategoriesQuery,
 useActivateOrSuspendCategoryMutation,
 useDeleteSingleSubCategoryMutation,
 useUpdateSubCategoryMutation,
 useActivateOrSuspendSubCategoryMutation,
 // skills
 useGetAllSkillsQuery,
 useGetSkillsByUsertypeQuery,
 useAddSkillMutation,
 useGetSingleSkillQuery,
 useDeleteSkillMutation,
 useActivateOrDeactivateSKillMutation,
 useUpdateSkillMutation,
 // tags
 useGetAllTagsQuery,
 useAddTagMutation,
 useUpdateTagMutation,
 useDeleteTagMutation,
 useGetSingleTagQuery,
 // kyc
 useApproveUserKycMutation,
 useDeclineUserKycMutation,
 // bannedWords
 useAddBannedWordMutation,
 useDeleteBannedWordMutation,
 useGetAllBannedWordsQuery,
 // referral
 useGetAllReferralSettingQuery,
 useGetInfluencerReferralSettingQuery,
 useUpdateReferralSettingMutation,
 useUpdateInfluencerReferralSettingMutation,
 useEnableOrDisableReferralSettingMutation,
 useEnableOrDisableInfluencerReferralSettingMutation,
 // socials
 useUpdateSocialMutation,
 useAddSocialMutation,
 useGetAllSocialsQuery,
 useDeleteSocialMutation,
 useActivateOrDeactivateSocialMutation,
 // location settings
 useGetAllCountriesQuery,
 useGetOneCountryQuery,
 useAddCountryMutation,
 useEditCountryMutation,
 useDeleteCountryMutation,
 useGetStatesByCountryQuery,
 useGetSingleStateQuery,
 useAddStateMutation,
 useEditStateMutation,
 useSuspendStateMutation,
 useDeleteStateMutation,
 useGetCityByStateQuery,
 useAddCityMutation,
 useEditCityMutation,
 useSuspendCityMutation,
 useDeleteCityMutation,
 // service charges
 useGetAllServiceChargeQuery,
 useUpdateSingleServiceChargeOptionMutation,
 // campaign
 useGetAllCampaignSourcesQuery,
 useGetOneCampaignSourceQuery,
 useCreateChannelMutation,
 useGetOneCampaignChannelQuery,
 useEditChannelMutation,
 useUpdateChannelStatusMutation,
 useDeleteChannelMutation,
 useCreateSubTaskMutation,
 useEditSubTaskMutation,
 useDeleteSubTaskMutation,
 // Promotions
 useGetAllPromotionQuery,
 useCreatePromotionMutation,
 useEditPromotionMutation,
 // users section
 useGetAllUsersQuery,
 useGetSingleUserDetailQuery,
 useGetUsersStatQuery,
 useSuspendUserMutation,
 useActivateUserMutation,
 // services
 useGetAllServicesQuery,
 useGetSingleServicesQuery,
 useGetServicesStatQuery,
 useUpdateServiceStatusMutation,
 // dashboard data
 useGetDashboardDataQuery,
 useGetDashboardStatsQuery,
 // Clients
 useGetAllClientsQuery,
 useGetClientStatsQuery,
 useGetSingleClientQuery,
 useGetSingleClientStatsQuery,
 useCreateClientMutation,
 useGetClientProductsQuery,
 useGetClientProductsStatsQuery,
 useGetSingleClientProductQuery,
 useCreateClientProductMutation,
 useUpdateClientProductMutation,
 useUploadClientProductImageMutation,
 useDeleteClientProductImageMutation,
 useUpdateClientProfileMutation,
 useRemoveClientProfilePhotoMutation,
 useSuspendClientAccountMutation,
 useActivateClientAccountMutation,
 // Creators
 useGetAllCreatorsQuery,
 useGetCreatorsStatsQuery,
 useGetOneCreatorQuery,
 useGetSingleCreatorStatsQuery,
 useSuspendCreatorAccountMutation,
 useActivateCreatorAccountMutation,
 // tickets
 useGetAllTicketsQuery,
 useGetSingleTicketQuery,
 useGetTicketByStatusQuery,
 useGetTicketsStatsQuery,
 useUpdateTicketStatusMutation,
 // influencers
 useGetInfluencersQuery,
 useGetAllInfluencersByAccessTypeQuery,
 useGetInfluencerStatsQuery,
 useGetOneInfleuncerQuery,
 useActivateInfluencerAccountMutation,
 useSuspendInfluencerAccountMutation,
 useGetSingleInfluencerStatsQuery,
 // user pending verification
 useGetNewUsersQuery,
 useGetPendingVerificationQuery,
 useGetNewVerifiedUsersQuery,
 useDeleteUserMutation,
 useUnSuspendUserMutation,

 // notifications
 useGetNotificationQuery,
 useMarkMultipleAsReadMutation,
 useMarkNotificationAsReadMutation,
} = generalApi;
