import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import AutoCompleteField from './AutoCompleteField'; // Reusing your AutoCompleteField component
import { CircularProgress } from '@mui/material';
import { useGetProductCategoriesQuery } from 'services/auth.api';

export default function MultiLevelCategoryPicker({
  name,
  label,
  required = true,
  className = '',
  initialCategoryId,
  initialSubCategoryId,
  initialSubSubCategoryId,
  ...props
}) {
  const { control, watch, setValue } = useFormContext();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);

  const { data: productCategories, isLoading: categoriesLoading } = useGetProductCategoriesQuery();

  const categoryId = watch('category_id');
  const subCategoryId = watch('sub_category_id');
  const subSubCategoryId = watch('sub_sub_category_id');

  const categoryOptions = productCategories?.data?.map((category) => ({
    label: category.name,
    value: category.id,
  })) || [];

  const subCategoryOptions = selectedCategory
    ? selectedCategory.sub_categories?.map((subCategory) => ({
        label: subCategory.name,
        value: subCategory.id,
      }))
    : [];

  const subSubCategoryOptions = selectedSubCategory
    ? selectedSubCategory.sub_categories?.map((subSubCategory) => ({
        label: subSubCategory.name,
        value: subSubCategory.id,
      }))
    : [];

  // Handle the category selection
  const handleCategoryChange = (value) => {
    const category = productCategories?.data.find((c) => c.id === value.value);
    // {"label": "Health & Beauty","value": 37}
    setSelectedCategory(category);
    setSelectedSubCategory(null);
    setSelectedSubSubCategory(null);
    setValue('sub_category_id', null);
    setValue('sub_sub_category_id', null);
  };

  const handleSubCategoryChange = (value) => {
    const subCategory = selectedCategory.sub_categories.find((sc) => sc.id === value.value);
    setSelectedSubCategory(subCategory);
    setSelectedSubSubCategory(null);
    setValue('sub_sub_category_id', null);
  };

  const handleSubSubCategoryChange = (value) => {
    setSelectedSubSubCategory(value);
  };

  // UseEffect to set initial values based on props
  useEffect(() => {
    if (initialCategoryId && !selectedCategory) {
      const category = productCategories?.data.find((c) => c.id === initialCategoryId);
      setSelectedCategory(category);
    }

    if (initialSubCategoryId && selectedCategory && !selectedSubCategory) {
      const subCategory = selectedCategory.sub_categories.find((sc) => sc.id === initialSubCategoryId);
      setSelectedSubCategory(subCategory);
    }

    if (initialSubSubCategoryId && selectedSubCategory && !selectedSubSubCategory) {
      const subSubCategory = selectedSubCategory.sub_categories.find((ssc) => ssc.id === initialSubSubCategoryId);
      setSelectedSubSubCategory(subSubCategory);
    }
  }, [
    productCategories,
    initialCategoryId,
    initialSubCategoryId,
    initialSubSubCategoryId,
    selectedCategory,
    selectedSubCategory,
    selectedSubSubCategory
  ]);
  
  return (
    <div className={`form-group-wrapper ${className}`}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <>
            {/* Category Picker */}
            <AutoCompleteField
              name="category_id"
              label="Select Category"
              required
              selectOption={categoryOptions}
              // defaultValue={selectedCategory?.name || categoryId}
              defaultValue={categoryOptions.find(option => option.value === selectedCategory?.value) || null}
              handleCustomChange={(value) => {
                onChange(value?.value);
                handleCategoryChange(value);
              }}
              placeholder="Select a category"
              loading={categoriesLoading}
              {...props}
            />

            {/* Subcategory Picker (conditionally rendered) */}
            {(selectedCategory && selectedCategory.sub_categories.length > 0) && (
              <AutoCompleteField
                name="sub_category_id"
                label="Select Subcategory"
                required
                selectOption={subCategoryOptions}
                // defaultValue={selectedSubCategory?.name || subCategoryId}
                defaultValue={subCategoryOptions.find(option => option.value === selectedSubCategory?.value) || null}
                handleCustomChange={(value) => {
                  onChange(value?.value);
                  handleSubCategoryChange(value);
                }}
                placeholder="Select a subcategory"
                loading={categoriesLoading}
                {...props}
              />
            )}

            {/* Sub-subcategory Picker (conditionally rendered) */}
            {(selectedSubCategory && selectedSubCategory.sub_categories.length > 0) && (
              <AutoCompleteField
                name="sub_sub_category_id"
                label="Select Sub-subcategory"
                required
                selectOption={subSubCategoryOptions}
                // defaultValue={selectedSubSubCategory?.name || subSubCategoryId}
                defaultValue={subSubCategoryOptions.find(option => option.value === selectedSubSubCategory?.value) || null}
                handleCustomChange={(value) => {
                  onChange(value?.value);
                  handleSubSubCategoryChange(value);
                }}
                placeholder="Select a sub-subcategory"
                loading={categoriesLoading}
                {...props}
              />
            )}
          </>
        )}
      />
    </div>
  );
}
