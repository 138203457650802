import StatCard from "components/StatCard";
import InfluencerIcon from "components/Vectors/InfluencerIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetInfluencerStatsQuery, useGetInfluencersQuery } from "services";
import { useState, useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import InfluencersTab from "./Tabs/InfluencersTab";

const AdminInfluencers = () => {
  const [activeTab, setactiveTab] = useState("affiliate");
  const navigate = useNavigate();
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("tab");
  useEffect(() => {
   if (tab) setactiveTab(tab);
  }, [tab]);

 const { data: allInfluencerStats } = useGetInfluencerStatsQuery();

 const statCards = [
  // {
  //   name: "All Influencers",
  //   value: allInfluencerStats?.data?.total,
  //   iconBg: "bg-[#FAF5FF]",
  //   icon: <InfluencerIcon width={32} height={32} color="#8937CE" />,
  //   percentage: null,
  // },
  {
   name: "Active Influencers",
   value: allInfluencerStats?.data?.active,
   iconBg: "bg-success-100",
   icon: <InfluencerIcon width={32} height={32} color="#00A04A" />,
   percentage: null,
  },
  {
   name: "Inactive Influencers",
   value: allInfluencerStats?.data?.inactive,
   iconBg: "bg-gray-100",
   icon: <InfluencerIcon width={32} height={32} color="#8585A3" />,
   percentage: null,
  },
  {
   name: "Suspended Influencers",
   value: allInfluencerStats?.data?.suspended,
   iconBg: "bg-[#D61E341A]",
   icon: <InfluencerIcon width={32} height={32} color="#D61E34" />,
   percentage: null,
  },
  {
   name: "Influencers > 2000 followers",
   value: allInfluencerStats?.data?.affiliate,
   iconBg: "bg-orange-100",
   icon: <InfluencerIcon width={32} height={32} color="#F97316" />,
   percentage: null,
  },
  {
   name: "Influencers < 2000 followers",
   value: allInfluencerStats?.data?.basic,
   iconBg: "bg-violet-100",
   icon: <InfluencerIcon width={32} height={32} color="#8B5CF6" />,
   percentage: null,
  },
 ];

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <div className="flex justify-between items-center mb-4">
    <h1 className="title">Influencers</h1>
   </div>

   {/* Cards */}
   <div className="grid-cols-2 lg:grid-cols-3 grid gap-4">
    {statCards.map((statCard, index) => (
     <StatCard key={index} {...statCard} />
    ))}
   </div>

   {/* Table */}
   <div className="mt-5">
    <TabContext value={activeTab}>
     <TabList
      variant="scrollable"
      scrollButtons="auto"
      className="mt-6 font-semibold"
      value={activeTab}
      sx={{
       textTransform: "capitalize",
      }}
      onChange={(e, newValue) => {
       navigate(`?tab=${newValue}`);
       setactiveTab(newValue);
      }}
      aria-label="basic tabs example"
     >
      <Tab label={"Influencers above 2000 followers"} value="affiliate" />
      <Tab label={"Influencers below 2000 followers"} value="basic" />
      {/* <Tab label={"Suspended campaigns"} value="suspended" /> */}
     </TabList>
     <TabPanel value="affiliate">
      <InfluencersTab status="affiliate" />
     </TabPanel>
     <TabPanel value="basic">
      <InfluencersTab status="basic" />
     </TabPanel>
     {/* <TabPanel value="suspended">
      <CampaignsTab status="suspended" />
     </TabPanel> */}
    </TabContext>
   </div>
  </div>
 );
};

export default AdminInfluencers;
