function UserManagementIcon(props) {
 return (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
     d="M18 18.5L18.278 17.114C18.424 16.381 18.816 15.704 18.956 14.97C18.986 14.8173 19.0007 14.6607 19 14.5C18.9996 14.1521 18.9265 13.808 18.7855 13.4899C18.6445 13.1718 18.4387 12.8867 18.1811 12.6527C17.9236 12.4187 17.6201 12.2411 17.2899 12.1311C16.9598 12.0212 16.6104 11.9814 16.264 12.0142C15.9176 12.0471 15.5819 12.1519 15.2783 12.3219C14.9747 12.4919 14.71 12.7235 14.501 13.0017C14.2921 13.2799 14.1435 13.5987 14.0648 13.9376C13.9861 14.2765 13.979 14.6282 14.044 14.97C14.184 15.705 14.576 16.38 14.723 17.114L15 18.5M18 18.5H15M18 18.5L20.497 19.166C20.9233 19.2609 21.3045 19.4982 21.5778 19.8389C21.851 20.1796 22 20.6033 22 21.04C22 21.57 21.57 22 21.04 22H11.96C11.7054 22 11.4612 21.8989 11.2812 21.7188C11.1011 21.5388 11 21.2946 11 21.04C11 20.14 11.625 19.361 12.503 19.166L15 18.5M17 9V8C17 5.172 17 3.757 16.121 2.879C15.243 2 13.828 2 11 2H8C5.172 2 3.757 2 2.879 2.879C2 3.757 2 5.172 2 8V16C2 18.828 2 20.243 2.879 21.121C3.757 22 5.172 22 8 22"
     stroke={props.color || "#D0D0EF"}
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
    />
    <path
     d="M7 8.667C7 8.667 7.625 8.667 8.25 10C8.25 10 10.235 6.667 12 6M6 14H10M6 17H10"
     stroke={props.color || "#D0D0EF"}
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
    />
   </svg>
 );
}

export default UserManagementIcon;
